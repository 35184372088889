// @import 'normalize.css';

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    text-rendering: optimizespeed;
    overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

// Fonts emmbeded

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;
